<template>
  <div class="container">
    <div class="card border-0 shadow-none">
      <div class="card-body">
        <div class="row mb-4">
          <div class="col">
            <h4 class="mb-0 text-success fw-bold">New Email Template</h4>
          </div>
          <div class="col-auto ms-auto">
            <router-link
              to="/email-templates"
              class="btn btn-sm btn-outline-success"
            >
              <i class="far fa-arrow-left me-2"></i>Back
            </router-link>
          </div>
        </div>

        <!--  -->

        <div class="row mb-4">
          <div class="col">
            <label for="">Title</label>
            <input type="text" v-model="template.title" class="form-control" />
          </div>
          <div class="col">
            <label for="">Subject</label>
            <input
              type="text"
              v-model="template.subject"
              class="form-control"
            />
          </div>
        </div>

        <vue-editor class="mb-4" v-model="template.body"></vue-editor>

        <!--  -->

        <div class="row">
          <div class="col-auto ms-auto">
            <button class="btn btn btn-success" @click="saveTemplate">
              <i class="far fa-check me-2"></i>Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../components/loaders/Loading.vue";
import { VueEditor } from "vue2-editor";

export default {
  data() {
    return {
      template: {
        title: "",
        subject: "",
        body: "",
        status: "draft"
      },
    };
  },
  methods: {
    saveTemplate() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/email-templates/",
          this.template
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);

          this.$router.push("/email-templates");
        });
    },
  },
  mounted() {
    this.$store.sidebarOpen = false;
  },
  components: {
    Loading,
    VueEditor,
  },
};
</script>

<style></style>
